import React, { useState, useEffect } from 'react';
import VehicleList from './VehicleList';
import TyreSection from './TyreSection';

function VehicleViewer() {
  const [selectedVehicleId, setSelectedVehicleId] = useState(null);
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [selectedVehicleRZ, setSelectedVehicleRZ] = useState('');
  const [title, setTitle] = useState('');
  const [refreshKey, setRefreshKey] = useState(0); // Přidán state pro obnovovací klíč

  useEffect(() => {
    const interval = setInterval(() => {
      setRefreshKey(prevKey => prevKey + 1); // Aktualizace refreshKey každé 2 sekundy
    }, 2000);

    return () => clearInterval(interval); // Zrušení intervalu při odmontování komponenty
  }, []);

  const handleSelectVehicle = (vehicleId, templateId, vehicleRZ) => {
    setSelectedVehicleId(vehicleId);
    setSelectedTemplateId(templateId);
    setSelectedVehicleRZ(vehicleRZ);
    setTitle(vehicleRZ);
  };

  return (
    <div className="vehicle-viewer d-flex flex-column">
      <div className="header-section mb-3">
        <h2>{title || 'Seznam vozidel'}</h2>
      </div>
      <div className="content-section d-flex flex-column flex-md-row">
    <div className="vehicle-list w-100 w-md-50">
        <VehicleList onSelectVehicle={handleSelectVehicle} refreshKey={refreshKey} />
        </div>
        <div className="tyre-section w-100 w-md-50">
          {/* Předání refreshKey do TyreSection jako způsob, jak vyvolat obnovení */}
          <TyreSection templateId={selectedTemplateId} title={title} RZ={selectedVehicleRZ} refreshKey={refreshKey} />
        </div>
      </div>
    </div>
  );
}

export default VehicleViewer;
