import React, { useState } from 'react';
import { Modal, Button, FormControl, FormGroup, FormLabel, FormSelect } from 'react-bootstrap';
import * as XLSX from 'xlsx';

const ExportImportModal = ({ show, handleClose, onSubmit }) => {
  const [versionName, setVersionName] = useState('');
  const [file, setFile] = useState(null);
  const [sheets, setSheets] = useState([]);
  const [selectedSheets, setSelectedSheets] = useState([]);

  const resetForm = () => {
    setVersionName('');
    setFile(null);
    setSheets([]);
    setSelectedSheets([]);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const workbook = XLSX.read(e.target.result, { type: 'binary' });
        setSheets(workbook.SheetNames);
      };
      reader.readAsBinaryString(file);
    }
  };

  const handleSubmit = () => {
    if (file && selectedSheets.length > 0) {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("versionName", versionName);
        // Zde přidáme kontrolu, zda selectedSheets je opravdu pole
        formData.append("selectedSheets", JSON.stringify(Array.isArray(selectedSheets) ? selectedSheets : []));
        
        onSubmit(formData); // předpokládá, že onSubmit je prop
        handleClose(); // předpokládá, že handleClose je prop
        resetForm(); // resetuje form data
    } else {
        console.error("Chybí soubor nebo nebyly vybrány žádné listy");
    }
};

  return (
    <Modal show={show} onHide={() => { handleClose(); resetForm(); }}>
      <Modal.Header closeButton>
        <Modal.Title>Import Dat</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormGroup>
          <FormLabel>Vyberte soubor</FormLabel>
          <FormControl type="file" onChange={handleFileChange} />
        </FormGroup>
        {sheets.length > 0 && (
          <FormGroup>
            <FormLabel>Vyberte list(y)</FormLabel>
            <FormSelect multiple onChange={(e) => setSelectedSheets([...e.target.selectedOptions].map(option => option.value))}>
              {sheets.map(sheet => (
                <option key={sheet} value={sheet}>{sheet}</option>
              ))}
            </FormSelect>
          </FormGroup>
        )}
        <FormGroup>
          <FormLabel>Název verze</FormLabel>
          <FormControl 
            type="text" 
            value={versionName} 
            onChange={(e) => setVersionName(e.target.value)} 
            placeholder="Zadejte název verze" 
          />
        </FormGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => { handleClose(); resetForm(); }}>Zavřít</Button>
        <Button variant="primary" onClick={handleSubmit}>Provést</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ExportImportModal;
