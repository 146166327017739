import React, { useState, useEffect } from 'react';
import { Modal, Table, Button } from 'react-bootstrap';

const SearchSelectModal = ({ show, handleClose, fetchItems, onSelect }) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (show) {
      fetchItems().then(fetchedItems => {
        if (Array.isArray(fetchedItems)) {
          setItems(fetchedItems);
        } else {
          console.warn('fetchItems did not return an array:', fetchedItems);
          setItems([]); // Zajistěte, že `items` bude pole i v případě chyby
        }
      })
      .catch(error => {
        console.error("Error fetching items:", error);
        setItems([]); // Nastaví `items` na prázdné pole v případě chyby
      });
    }
  }, [show, fetchItems]);

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Vyhledávání a výběr</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Název Filtru</th>
              <th>Vytvořeno</th>
              <th>Vytvořil</th>
              <th>Template URL</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item) => (
              <tr key={item.filterId} onClick={() => onSelect(item)}> 
                <td>{item.filterName}</td>
                <td>{new Date(item.createdAt).toLocaleDateString("cs-CZ")}</td>
                <td>{item.userId}</td>
                <td>
                  {item.filterURL ? (
                    <a href={item.filterURL} target="_blank" rel="noopener noreferrer">View Template</a>
                  ) : 'N/A'}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>Zavřít</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SearchSelectModal;
