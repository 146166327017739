import Login from './components/Login';
import Register from './components/Register';
import React, { useState,  useEffect, useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, Link } from 'react-router-dom';
import { AppBar, Box, CssBaseline, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Toolbar, Typography, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import HomeIcon from '@mui/icons-material/Home'; // Příklad ikony, můžete přizpůsobit podle potřeby
import LeftMenu from './LeftMenu';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';


// Importujte vaše komponenty
import ServiceSheet from './components/ServiceSheet';
import ServiceConfig from './components/ServiceConfig';
import Analytics from './components/Analytics';
import ImportPLOR from './components/ImportPLOR';
import Calculation from './components/Calculation';
import ZakladniSlevy from './components/ZakladniSlevy';
import PolozkyB2B from './components/PolozkyB2B';
import AxLiveProducts from './components/AxLiveProducts';
import ObjednavkyB2B from './components/ObjednavkyB2B';
import ObjednavkaB2BDetail from './components/ObjednavkaB2BDetail';
import VehicleTemplatesViewer from './components/VehicleTemplatesViewer'; 
import VehicleViewer from './components/VehicleViewer'; 
import SE_OrderList from './components/SE_OrderList';
import SE_OrderDetail from './components/SE_OrderDetail';
import SE_OrdersPicking from './components/SE_OrdersPicking';
import AkcePolozka from './components/AkcePolozka'
import Netto from './components/Netto'
import Vyprodej from './components/Vyprodej'
import ProductDetail from './components/ProductDetail';
import ProductList from './components/ProductList'; // Import the new component


// Další importy...

import { AuthProvider, useAuth } from './AuthContext';  // Předpokládám existenci AuthContext

const drawerWidth = 240;

const ProtectedRoute = ({ children, isAuthenticated }) => {
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }
  return children;
};

function App() {
  
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    const token = localStorage.getItem('authToken');
    return !!token;
  });
    const isDesktopOrLaptop = useMediaQuery(theme.breakpoints.up('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); 
  const [open, setOpen] = useState(isDesktopOrLaptop); // Defaultně otevřené pro desktop a laptop, jinak zavřené
  

  const handleDrawerToggle = () => {
    setOpen(!open);
  };
  useEffect(() => {
    setOpen(isDesktopOrLaptop);
  }, [isDesktopOrLaptop]);

  const drawerWidth = 240;
  return (
    <ThemeProvider theme={theme}>
    <AuthProvider>
      <Router>
      <Box
  component="main"
  sx={{
    flexGrow: 1,
    p: 3,
    width: { sm: `calc(100% - ${open ? drawerWidth : 0}px)` }, // Dynamicky upravuje šířku
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `${open ? drawerWidth : 0}px`, // Posouvá obsah, když je menu otevřené
  }}
>
          <CssBaseline />
          <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ zIndex: theme => theme.zIndex.drawer + 2 }}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap component="div">
                Název aplikace
              </Typography>
              {isAuthenticated && (
                <Button color="inherit" onClick={() => setIsAuthenticated(false)}>
                  Odhlásit se
                </Button>
              )}
            </Toolbar>
          </AppBar>
          {/* Použijte LeftMenu zde */}
          <LeftMenu open={open} handleDrawerToggle={handleDrawerToggle} />
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <Toolbar />
            <Routes>
              <Route path="/login" element={!isAuthenticated ? <Login setIsAuthenticated={setIsAuthenticated} /> : <Navigate replace to="/servicesheet" />} />
              <Route path="/register" element={!isAuthenticated ? <Register /> : <Navigate replace to="/servicesheet" />} />
              <Route path="/servicesheet" element={<ProtectedRoute isAuthenticated={isAuthenticated}><ServiceSheet /></ProtectedRoute>} />
              <Route path="/serviceconfig" element={<ProtectedRoute isAuthenticated={isAuthenticated}><ServiceConfig /></ProtectedRoute>} />
              <Route path="/analytics" element={<ProtectedRoute isAuthenticated={isAuthenticated}><Analytics /></ProtectedRoute>} />
              <Route path="/import-plor" element={<ProtectedRoute isAuthenticated={isAuthenticated}><ImportPLOR /></ProtectedRoute>} />
              <Route path="/calculation" element={<ProtectedRoute isAuthenticated={isAuthenticated}><Calculation/></ProtectedRoute>} />
              <Route path="/zakladni_slevy" element={<ProtectedRoute isAuthenticated={isAuthenticated}><ZakladniSlevy /></ProtectedRoute>} />
              <Route path="/akce_polozka" element={<ProtectedRoute isAuthenticated={isAuthenticated}><AkcePolozka/></ProtectedRoute>} />
              <Route path="/Netto" element={<ProtectedRoute isAuthenticated={isAuthenticated}><Netto/></ProtectedRoute>} />
              <Route path="/Vyprodej" element={<ProtectedRoute isAuthenticated={isAuthenticated}><Vyprodej/></ProtectedRoute>} />
              <Route path="/productdetail/:polozka" element={<ProtectedRoute isAuthenticated={isAuthenticated}><ProductDetail /></ProtectedRoute>} />
              <Route path="/polozkyb2b" element={<PolozkyB2B />} />
              <Route path="/productlist" element={<ProtectedRoute isAuthenticated={isAuthenticated}><ProductList /></ProtectedRoute>} />
              <Route path="/ax-live-products" element={<ProtectedRoute isAuthenticated={isAuthenticated}><AxLiveProducts /></ProtectedRoute>} />
              <Route path="/objednavky-b2b" element={<ProtectedRoute isAuthenticated={isAuthenticated}><ObjednavkyB2B /></ProtectedRoute>} />
              <Route path="/objednavka-b2b-detail/:orderId" element={<ObjednavkaB2BDetail />} />
              <Route path="/vehicle-templates" element={<ProtectedRoute isAuthenticated={isAuthenticated}><VehicleTemplatesViewer /></ProtectedRoute>} />
              <Route path="/vehicle-viewer" element={<ProtectedRoute isAuthenticated={isAuthenticated}><VehicleViewer /></ProtectedRoute>} />
              <Route path="/se_orderlist" element={<ProtectedRoute isAuthenticated={isAuthenticated}><SE_OrderList /></ProtectedRoute>} />
              <Route path="/eforder/:orderNumber" element={<ProtectedRoute isAuthenticated={isAuthenticated}><SE_OrderDetail /></ProtectedRoute>} />
              <Route path="/eforders-picking" element={<ProtectedRoute isAuthenticated={isAuthenticated}><SE_OrdersPicking /></ProtectedRoute>} />

{/* Definice cest... */}
            </Routes>
          </Box>
        </Box>
      </Router>
    </AuthProvider>
    </ThemeProvider>
  );
}

export default App;