import React, { useState, useEffect } from 'react';
import { useTable, useFilters, useGlobalFilter } from 'react-table';
import * as XLSX from 'xlsx';
import SearchSelectModal from './SearchSelectModal';
import ExportImportModal from './ExportImportModal';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../AuthContext'; 

// Komponenta pro výchozí sloupcový filtr
function DefaultColumnFilter({ column: { filterValue, setFilter } }) {
  return (
    <input
      value={filterValue || ''}
      onChange={e => setFilter(e.target.value || undefined)}
      placeholder="Hledat..."
    />
  );
}

// Globální filtr
function GlobalFilter({ globalFilter, setGlobalFilter }) {
  return (
    <span>
      Hledat: {' '}
      <input
        value={globalFilter || ''}
        onChange={e => setGlobalFilter(e.target.value || undefined)}
        placeholder="Všechny sloupce..."
      />
    </span>
  );
}

function ImportPLOR() {
  const [file, setFile] = useState(null);
  const [versionName, setVersionName] = useState('');
  const [data, setData] = useState([]);
  const [showPLORModal, setShowPLORModal] = useState(false);
  const [showIMPModal, setShowIMPModal] = useState(false);
  const [selectedPLORVersion, setSelectedPLORVersion] = useState(null);
  const [selectedIMPVersion, setSelectedIMPVersion] = useState(null);
  const [showExportImportModal, setShowExportImportModal] = useState(false);
  const [importData, setImportData] = useState({ file: null, versionName: '' });
  const [selectedSheets, setSelectedSheets] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser } = useAuth();
  useEffect(() => {
    // Příklad použití currentUser v useEffect, pokud je to potřebné
    console.log("Aktuální uživatel je:", currentUser);
  }, [currentUser]);

useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const plorVerze = queryParams.get('plorVerze');
    const impVerze = queryParams.get('impVerze');

    if (plorVerze || impVerze) {
      setSelectedVersions(prev => ({
        ...prev,
        PLOR: { ...prev.PLOR, VersionID: plorVerze || prev.PLOR.VersionID },
        IMP: { ...prev.IMP, VersionID: impVerze || prev.IMP.VersionID }
      }));
    }
  }, [location]); 
useEffect(() => {
  const queryParams = new URLSearchParams(window.location.search);
  const plorVerzeId = queryParams.get('plorVerze');
  const impVerzeId = queryParams.get('impVerze');

  if (plorVerzeId) {
    fetchVersionName(plorVerzeId, 'PLOR').then(versionName => {
      setSelectedVersions(prev => ({
        ...prev,
        PLOR: { VersionID: plorVerzeId, VersionName: versionName }
      }));
    });
  }
  if (impVerzeId) {
    fetchVersionName(impVerzeId, 'IMP').then(versionName => {
      setSelectedVersions(prev => ({
        ...prev,
        IMP: { VersionID: impVerzeId, VersionName: versionName }
      }));
    });
  }
}, []);
  
 const updateURL = () => {
    const newURL = generateURLWithParams(selectedVersions);
    navigate(newURL, { replace: true }); // Použijte 'replace: true', pokud nechcete přidávat nový záznam do historie prohlížeče
  };


const [selectedVersions, setSelectedVersions] = useState({
  PLOR: { VersionID: null, VersionName: '' },
  IMP: { VersionID: null, VersionName: '' }
});


  const handleSelectVersion = (version, module) => {
  setSelectedVersions(prev => ({...prev, [module]: version.VersionID}));
};
  
  const handleOpenExportImportModal = () => {
  setShowExportImportModal(true);
};

const loadComparison = async () => {
  const { PLOR, IMP } = selectedVersions;
  if (!PLOR.VersionID || !IMP.VersionID) {
    console.log('Nejsou vybrány všechny potřebné verze pro srovnání.');
    return;
  }

  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/get-comparison-data?plorVerze=${PLOR.VersionID}&impVerze=${IMP.VersionID}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    setData(data);
    console.log("Data z /get-comparison-data:", data);
  } catch (error) {
    console.error("Chyba při načítání srovnávacích dat: ", error);
  }
};

  const fetchPLORTemplates = async () => {
    try {
      console.log(`Fetching PLOR templates for PLOR component`);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/search-versions?componentType=PLOR`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.log("API call success, data received for PLOR:", data);
      return data; // Vrací celé pole šablon
    } catch (error) {
      console.error("Error fetching PLOR templates: ", error);
      return []; // V případě chyby vrací prázdné pole
    }
  };
  const fetchIMPTemplates = async () => {
    try {
      console.log(`Fetching IMP templates for PLAX component`);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/search-versions?componentType=PLAX`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.log("API call success, data received for PLOR:", data);
      return data; // Vrací celé pole šablon
    } catch (error) {
      console.error("Error fetching IMP templates: ", error);
      return []; // V případě chyby vrací prázdné pole
    }
  };
  

  
  const fetchData = async (plorFilterId, impFilterId) => {
    try {
      // Zde předpokládáme, že plorFilterId a impFilterId jsou již definovány
      // a odpovídají vybraným verzím/filterům pro PLOR a IMP (PLAX) komponenty.
      const response = await fetch(`${process.env.REACT_APP_API_URL}/get-comparison-data?plorVerze=${plorFilterId}&impVerze=${impFilterId}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.log("Data z /get-comparison-data:", data);
      // Zde byste měli implementovat logiku pro práci s daty - např. aktualizaci stavu komponenty
      setData(data); // Předpokládá, že máte ve vaší komponentě stav 'data' pro uložení těchto dat
    } catch (error) {
      console.error("Chyba při načítání srovnávacích dat: ", error);
    }
  };
//generování parametrů URL
const generateURLWithParams = (selectedVersions) => {
  const { PLOR, IMP } = selectedVersions;
  const queryParams = new URLSearchParams();

  if (PLOR.VersionID) {
    queryParams.append('plorVerze', PLOR.VersionID);
  }
  if (IMP.VersionID) {
    queryParams.append('impVerze', IMP.VersionID);
  }

  // Přidáváme pouze pokud existují parametry, aby URL zůstala čistá, pokud nejsou vybrány žádné verze
  return queryParams.toString() ? `/import-plor?${queryParams}` : '/import-plor';
};

// Funkce pro získání Názvu tlačítka z ID (při přímém zadání URL do prohlížeče)
async function fetchVersionName(versionId, module) {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/get-name-version-from-id?versionId=${versionId}&module=${module}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data.versionName; // Předpokládáme, že server vrací objekt s klíčem versionName
  } catch (error) {
    console.error("Chyba při načítání názvu verze: ", error);
    return ''; // V případě chyby vrátíme prázdný řetězec nebo můžete zvolit jiné chování
  }
}
// Funkce pro výběr PLOR verze
const handleSelectPLORTemplate = (template) => {
  setSelectedVersions(prevVersions => ({
    ...prevVersions,
    PLOR: { VersionID: template.filterId, VersionName: template.filterName || '' }
  }));
  setShowPLORModal(false); // Předpokládá, že máte logiku pro zavření modalu
};

const handleSelectIMPTemplate = (template) => {
  setSelectedVersions(prevVersions => ({
    ...prevVersions,
    IMP: { VersionID: template.filterId, VersionName: template.filterName || '' }
  }));
  setShowIMPModal(false); // Předpokládá, že máte logiku pro zavření modalu
};

// Funkce pro reset výběru verze
const resetVersionSelection = (module) => {
  setSelectedVersions(prev => ({ ...prev, [module]: { VersionID: null, VersionName: '' }}));
};






// funkce volání API pro srovnání dat při vyplnění všech obrazů
useEffect(() => {
  // Rozbalení PLOR a IMP z selectedVersions
  const { PLOR, IMP } = selectedVersions;

  // Kontrola, zda jsou vybrány obě verze
  if (PLOR && IMP) {
  fetchData(PLOR.VersionID, IMP.VersionID);
}
}, [selectedVersions]);
  
// funkce pro exort do formátovaného xlsx
const exportToXLSX = () => {
  const ws = XLSX.utils.json_to_sheet(data.map(row => {
    // Příprava dat pro export s aplikací podmínek pro podbarvení
    return {
      ...row,
      // Příklad aplikace podmínky pro podbarvení
      // Toto je pouze ilustrativní, musíte upravit podle vaší logiky
      C_Polozky: row.shoda.includes('C_Polozky=IMP_c_polozky') ? 'Zelená: ' + row.C_Polozky : 'Červená: ' + row.C_Polozky,
      // Další sloupce...
    };
  }), {header: ['C_Polozky', 'shoda', 'C_Polozky_AX', 'Ext_cislo_AX', 'Nazev', 'Prodej_PLOR', 'Prodej_AX', 'prodej_datum_ceny_AX', 'dostupnost_Web_AX'], skipHeader: true});

  // Vytvoření nového workbooku
  const wb = XLSX.utils.book_new();

  // Přidání listu do workbooku
  XLSX.utils.book_append_sheet(wb, ws, "Data");

  // Uložení workbooku jako souboru
  XLSX.writeFile(wb, "exportedData.xlsx");
};

const handleExportImportSubmit = async (formData) => {
  // Zkontrolujte, zda je currentUser dostupný
  if (!currentUser || !currentUser.userID) {
    console.error('Uživatel není přihlášený nebo currentUser není dostupný.');
    alert('Uživatel není přihlášený nebo currentUser není dostupný.'); // Informování uživatele prostřednictvím vyskakovacího okna
    return;
  }

  // Předpokládáme, že formData je již připravená v komponentě ExportImportModal
  // a obsahuje vše potřebné včetně souboru, versionName a selectedSheets serializované jako JSON string

  // Doplnění userID do formData
  formData.append("userID", currentUser.userID);

  // Konstrukce URL s query parametry není potřeba, protože userID je součástí formData
  const url = `${process.env.REACT_APP_API_URL}/import-xlsx-an-PLOR`;

  try {
    const response = await fetch(url, {
      method: 'POST',
      body: formData, // odesíláme formData přímo
      // Přidání hlaviček pro autorizaci atd. pokud je potřeba
    });

    if (response.ok) {
      console.log("Data byla úspěšně importována");
      alert("Data byla úspěšně importována"); // Informování uživatele prostřednictvím vyskakovacího okna
      // Zde můžete přidat další akce po úspěšném importu
    } else {
      console.error("Nepodařilo se importovat data, status: ", response.status);
      alert(`Nepodařilo se importovat data, status: ${response.status}`); // Informování uživatele prostřednictvím vyskakovacího okna
    }
  } catch (error) {
    console.error("Došlo k chybě při importu: ", error);
    alert(`Došlo k chybě při importu: ${error}`); // Informování uživatele prostřednictvím vyskakovacího okna
  }
};
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };


  // Definice sloupců pro react-table
   const columns = React.useMemo(() => [
  {
  Header: 'Č. Položky - ceník',
  accessor: 'C_Polozky',
  Filter: DefaultColumnFilter,
  Cell: ({ row }) => {
    let style = {};
    if (row.original.shoda.includes('C_Polozky=IMP_c_polozky')) {
      style.backgroundColor = '#ccffcc'; // Zelená pro shodu C_Polozky=IMP_c_polozky
    } else if (row.original.shoda.includes('Ext_cislo_polozky=C_Polozky')) {
      style.backgroundColor = '#ffcc88'; // Oranžová pro Ext_cislo_polozky=C_Polozky
    } else {
      style.backgroundColor = '#ffcccc'; // Červená pro ostatní
    }

    return (
      <div style={style}>
        {row.values.C_Polozky}
      </div>
    );
  }
}
,
    {
  Header: 'Shoda',
  accessor: 'shoda',
  Filter: DefaultColumnFilter,
  Cell: ({ value }) => {
    let style = {};
    let text = value;

    if (value.includes('C_Polozky=IMP_c_polozky') || value.includes('Ext_cislo_polozky=C_Polozky')) {
      style = { backgroundColor: '#ccffcc', fontWeight: 'bold' }; // Zelené pozadí pro běžný produkt
      text = 'Běžný produkt';
    } else if (value === 'NESPÁROVÁNO - pouze v PLOR') {
      style = { backgroundColor: '#ffcc88', fontWeight: 'bold' }; // Oranžové pozadí pro novou položku
      text = 'Nová položka';
    } else if (value === 'NESPÁROVÁNO - pouze v IMP') {
      style = { backgroundColor: '#ffcccc', fontWeight: 'bold' }; // Červené pozadí pro ukončenou výrobu
      text = 'Ukončená výroba';
    }

    return <div style={style}>{text}</div>;
  }
},
    {
      Header: 'Ext. č. položky AX',
      accessor: 'Ext_cislo_AX',
       Filter: DefaultColumnFilter,
       Cell: ({ row }) => {
       const isMatched = row.original.shoda.includes('Ext_cislo_polozky=C_Polozky');
       return (
      <div style={{ backgroundColor: isMatched ? '#ccffcc' : 'transparent' }}>
        {row.values.Ext_cislo_AX}
      </div>
    );
  }
    },
    {
      Header: 'Název',
      accessor: 'Nazev',
      Filter: DefaultColumnFilter,
    },
    {
      Header: 'Prodej - Ceník',
      accessor: 'Prodej_PLOR',
      Filter: DefaultColumnFilter,
      Cell: ({ row }) => {
      const isMatched = row.original.shoda.includes('Prodej shodný');
       return (
      <div style={{ backgroundColor: isMatched ? '#ccffcc' : '#ffcccc' }}>
        {row.values.Prodej_PLOR}
      </div>
    );
  }
    },
    {
      Header: 'Prodej - AX',
      accessor: 'Prodej_AX',
      Filter: DefaultColumnFilter,
      Cell: ({ row }) => {
      const isMatched = row.original.shoda.includes('Prodej shodný');
       return (
      <div style={{ backgroundColor: isMatched ? '#ccffcc' : '#ffcccc'}}>
        {row.values.Prodej_AX}
      </div>
    );
  }
    },
  {
  Header: 'Dat. Prod. ceny',
  accessor: 'prodej_datum_ceny_AX',
  Filter: DefaultColumnFilter,
Cell: ({ value }) => {
    // Formátování data, pokud existuje hodnota
    return value ? new Date(value).toLocaleDateString("cs-CZ") : '';
  }
}
,
     {
      Header: 'Dostupnost Web',
      accessor: 'dostupnost_Web_AX',
      Filter: DefaultColumnFilter,
    },
    
    // Přidejte další sloupce podle vašich dat...
  ], []);

  
const defaultColumn = React.useMemo(() => ({
    Filter: DefaultColumnFilter, // Nastavení výchozího filtru pro všechny sloupce
  }), []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable({
    columns,
    data,
    defaultColumn, // Nastavení výchozího filtru pro všechny sloupce
  }, useFilters, useGlobalFilter);
  return (
     <div className="import-plor-container">
      <h2>Import Kalkulačního Ceníku</h2>
 
        <button onClick={handleOpenExportImportModal}>Otevřít Export/Import</button>
        
<button onClick={() => setShowPLORModal(true)}>
  {selectedVersions.PLOR.VersionName || "Vybrat ceník"}
  {selectedVersions.PLOR.VersionName && (
    <button onClick={(e) => {
      e.stopPropagation(); // Zabraňte bublání události
      resetVersionSelection('PLOR');
    }}>Reset</button>
  )}
</button>
<button onClick={() => setShowIMPModal(true)}>
  {selectedVersions.IMP.VersionName || "Vybrat obraz AX"}
  {selectedVersions.IMP.VersionName && (
    <button onClick={(e) => {
      e.stopPropagation(); // Zabraňte bublání události
      resetVersionSelection('IMP');
    }}>Reset</button>
  )}
</button>
    
        <button className="btn btn-success" onClick={exportToXLSX}>Exportovat do XLSX</button>

      <h2>Přehled Dat</h2>
     <GlobalFilter
  globalFilter={state.globalFilter}
  setGlobalFilter={setGlobalFilter}
/> 
     <div className="table-responsive">
        <table {...getTableProps()} className="table table-bordered table-striped">
          <thead>
  {headerGroups.map(headerGroup => (
    <tr {...headerGroup.getHeaderGroupProps()}>
      {headerGroup.headers.map(column => (
        <th {...column.getHeaderProps()}>
          {column.render('Header')}
          {/* Render filtru pro každý sloupec */}
          <div>{column.canFilter ? column.render('Filter') : null}</div>
        </th>
      ))}
    </tr>
  ))}
</thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <SearchSelectModal
        show={showPLORModal}
        handleClose={() => setShowPLORModal(false)}
        fetchItems={fetchPLORTemplates}
        onSelect={handleSelectPLORTemplate}
        placeholder="Vyhledat PLOR šablony..."
      />

      <SearchSelectModal
        show={showIMPModal}
        handleClose={() => setShowIMPModal(false)}
        fetchItems={fetchIMPTemplates}
        onSelect={handleSelectIMPTemplate}
        placeholder="Vyhledat IMP šablony..."
      /> 
      <ExportImportModal
        show={showExportImportModal}
        handleClose={() => setShowExportImportModal(false)}
        onSubmit={handleExportImportSubmit}
      />

</div>
  );
}

export default ImportPLOR;
