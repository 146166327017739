import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import ColumnFilter from './ColumnFilter';
import 'bootstrap/dist/css/bootstrap.min.css';

function VehicleList({ onSelectVehicle, refreshKey }) { // Změněno na VehicleList
  const [vehicles, setVehicles] = useState([]);
  const [selectedVehicleId, setSelectedVehicleId] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [onlyActiveVehicles, setOnlyActiveVehicles] = useState(false);

  const getFiltersFromURL = () => {
    const searchParams = new URLSearchParams(location.search);
    return Object.fromEntries(searchParams);
  };

  const fetchData = async (filters) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/search-vehicle-from-list`, { params: filters });
      setVehicles(response.data); // Změněno na setVehicles
    } catch (error) {
      console.error('Error loading vehicles:', error); // Změněno na 'vehicles'
    }
  };
  const handleOnlyActiveVehiclesChange = (checked) => {
    setOnlyActiveVehicles(checked);
    const searchParams = new URLSearchParams(location.search);
    if (checked) {
      searchParams.set('onRange', '1');
    } else {
      searchParams.delete('onRange');
    }
    navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
  };
  
  // Přidejte tento useEffect hook pro inicializaci zaškrtávacího pole na základě URL parametru při načtení
  useEffect(() => {
    const filters = getFiltersFromURL();
    fetchData(filters);
  }, [location.search, refreshKey]);

  const handleFilterChange = (filterName, value) => {
    const searchParams = new URLSearchParams(location.search);
    if (value) {
      searchParams.set(filterName, value);
    } else {
      searchParams.delete(filterName);
    }
    navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
  };

  const handleSelectVehicle = (idCar) => {
    const selectedVehicle = vehicles.find(vehicle => vehicle.idCar === idCar);
    if (selectedVehicle) {
      setSelectedVehicleId(idCar);
      // Ujistěte se, že pořadí je správně: idCar, templateId, RZ (jako vehicleName)
      onSelectVehicle(idCar, selectedVehicle.templateId, selectedVehicle.RZ);
    }
  };
  

  return (
    <div className="table-responsive">
      <div className="active-vehicles-checkbox">
  <input
    type="checkbox"
    id="onlyActiveVehicles"
    checked={onlyActiveVehicles}
    onChange={(e) => handleOnlyActiveVehiclesChange(e.target.checked)}
  />
  <label htmlFor="onlyActiveVehicles">Pouze aktivní vozidla</label>
</div>
      <table className="table table-bordered table-striped table-hover">
        <thead>
          <tr>
            <th>
              RZ
              <ColumnFilter filterName="RZ" onChange={handleFilterChange} placeholder="Filter by RZ" />
            </th>
            <th>
              Tractor
              <ColumnFilter filterName="tractor" onChange={handleFilterChange} placeholder="Filter by Tractor" />
            </th>
            <th>
              Tachograph Km
              <ColumnFilter filterName="tachographKm" onChange={handleFilterChange} placeholder="Filter by Tachograph Km" />
            </th>
            <th>
              Vehicle Type
              <ColumnFilter filterName="vehicleType" onChange={handleFilterChange} placeholder="Filter by Vehicle Type" />
            </th>
            <th>
              Company ID
              <ColumnFilter filterName="companyId" onChange={handleFilterChange} placeholder="Filter by Company ID" />
            </th>
          </tr>
        </thead>
        <tbody>
          {vehicles.map(vehicle => (
            <tr key={vehicle.idCar} 
                className={vehicle.idCar === selectedVehicleId ? 'table-primary' : ''} // Změněno na idCar
                onClick={() => handleSelectVehicle(vehicle.idCar)} // Změněno na idCar
                style={{cursor: 'pointer'}}>
              <td>{vehicle.RZ}</td>
              <td>{vehicle.tractor ? 'Yes' : 'No'}</td>
              <td>{vehicle.tachographKm}</td>
              <td>{vehicle.vehicleType}</td>
              <td>{vehicle.companyId}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default VehicleList; // Změněno na VehicleList
