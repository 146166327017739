import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ColumnFilter from './ColumnFilter';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Box, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, useMediaQuery } from '@mui/material';
import * as XLSX from 'xlsx';

function PolozkyB2B() {
  const [data, setData] = useState([]);
  const [tyreData, setTyreData] = useState([]);
  const [filterValues, setFilterValues] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser } = useAuth();
  const componentType = "PLAX";
  const [isUpdating, setIsUpdating] = useState(false);
  const exportToXlsx = () => {
    // Příprava dat pro export, včetně výpočtu rozdílu cen
    const dataForExport = data.map(item => {
      // Výpočet rozdílu cen, předpokládá se, že item.nejnizsiCena a item.ItsActionPrice jsou čísla
      let priceDifference = "N/A";
      if (item.ItsActionPrice === 0) {
        priceDifference = "Uprav akční cenu";
      } else if (item.nejnizsiCena !== null && item.ItsActionPrice !== null) {
        priceDifference = item.nejnizsiCena - item.ItsActionPrice;
      }
  
      return {
        "ID dodavatele": item.PrimaryVendorId,
        "Číslo položky": item.ItemId,
        "Výrobce": item.ItsItemName3,
        "Sk. řád. slevy": item.PurchLineDisc,
        "Název": item.ItemName,
        "Externí číslo položky": item.ExternalName,
        "Prodejní cena": item.SalesPrice,
        "V akci od": item.ItsActionDateFrom,
        "V akci do": item.ItsActionDateTo,
        "Akční cena": item.ItsActionPrice,
        "Marketingová akce": item.ItsMarketingActionId,
        "Dostupnost Web": item.ItsWebAvailable ? 'ANO' : 'NE',
        "Dostupnost B2B": item.ItsWebAvailableB2B ? 'ANO' : 'NE',
        "Skupina řádkové slevy": item.ItemGroupId,
        "Datum prodejní ceny": item.SalesPriceDate,
        "Nejnižší Cena B2B": item.nejnizsiCena,
        "Množství B2B": item.nejnizsiCenaMnozstvi,
        "Rozdíl ceny": priceDifference,
        // Přidejte další potřebné sloupce zde
      };
    });
  
    const worksheet = XLSX.utils.json_to_sheet(dataForExport); // Převod upravených dat na list
    const workbook = XLSX.utils.book_new(); // Vytvoření nového sešitu
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data"); // Přidání listu do sešitu
    XLSX.writeFile(workbook, "exported_data.xlsx"); // Export sešitu jako XLSX soubor
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsUpdating(true);
      try {
        // Získání parametrů z URL
        const searchParams = new URLSearchParams(location.search);
        
        // Přidání neměnného parametru
        searchParams.set('ItsWebAvailableB2B', 'Ano');
        
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/items`, { 
          params: Object.fromEntries(searchParams) 
        });
        setData(response.data);
        setIsUpdating(false);
      } catch (error) {
        console.error('Error loading data:', error);
        setIsUpdating(false);
      }
    };
  
    fetchData();
  }, [location]);


  const saveDataWithFilterToImp = async () => {
    // Získání názvu filtru od uživatele
    const filterNameUserInput = prompt('Zadejte název filtru:', 'DefaultníNázevFiltru');
    if (!filterNameUserInput) {
      alert('Uložení filtru bylo zrušeno, protože nebyl zadán žádný název filtru.');
      return; // Přerušení funkce, pokud uživatel nezadá název filtru
    }
  
    // Příprava dat k odeslání
    const preparedData = data.map(item => ({
      dodavatel: item.PrimaryVendorId ?? '',
      externi_cislo_polozky: item.ExternalItemId ?? '',
      nazev_produktu: item.ItemName ?? '',
      prodej_cena: item.SalesPrice ?? 0,
      minimalni_prodejni_cena: item.MinSalesPrice ?? 0, // Předpokládá se, že máte toto pole, přidejte správný klíč
      v_akci_od: item.ItsActionDateFrom ?? '',
      v_akci_do: item.ItsActionDateTo ?? '',
      akcni_cena: item.ItsActionPrice ?? 0,
      marketingove_akce: item.ItsMarketingActionId ?? '',
      c_polozky: item.ItemId ?? '',
      dostupnost_web: item.ItsWebAvailable ? 'ANO' : 'NE', // Předpokládá se, že true/false hodnoty jsou převedeny na 'ANO'/'NE'
      dostupnost_b2b: item.ItsWebAvailableB2B ? 'ANO' : 'NE',
      skupina_radkove_slevy: item.ItemGroupId ?? '',
      sk_polozek: item.SKU ?? 0, // Předpokládá se, že máte toto pole, přidejte správný klíč
      naklady_cena: item.CostPrice ?? 0, // Předpokládá se, že máte toto pole, přidejte správný klíč
      prodej_datum_ceny: item.SalesPriceDate ?? '',
      Verze: '1', // Předpokládá se, že 'Verze' je statická hodnota nebo získána z nějakého jiného zdroje
    }));
  
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/saveDataWithFilterToImp`, {
        data: preparedData,
        filterName: filterNameUserInput,
        userId: currentUser?.userID, // Ujistěte se, že klíč odpovídá tomu, co dostáváte z dekódování JWT nebo aktuálního uživatelského kontextu
        filterValues: JSON.stringify(Object.fromEntries(new URLSearchParams(location.search))),
        filterURL: `http://localhost:3001/polozkyb2Bb{location.search}`,
        componentType: 'PLAX'
      });
  
      alert('Data byla úspěšně uložena: ' + JSON.stringify(response.data));
    } catch (error) {
      console.error('Nepodařilo se uložit data:', error);
      alert('Nepodařilo se uložit data. Zkuste to prosím znovu.');
    }
  };
  
  const fetchTyreData = async () => {
    setIsUpdating(true);
    const itemIds = data.map(item => item.ItemId);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/getTyreData`, { items: itemIds });
      const fetchedTyreData = response.data;
  
      // Uložení načtených dat o pneumatikách do stavu tyreData
      setTyreData(fetchedTyreData);
  
      // Sloučení dat z fetchedTyreData s původním seznamem produktů v 'data'
      const updatedData = data.map(item => {
        const tyreInfo = fetchedTyreData.find(tyre => tyre.PartNo === item.ItemId);
        if (tyreInfo) {
          // Pokud byla nalezena informace o pneumatice, přidáme tyto informace do položky
          return {
            ...item,
            ...tyreInfo, // Přidáme všechny informace z tyreInfo do položky
            celkemPT: tyreInfo.Celkem,
            nejnizsiCena: tyreInfo.SPILowestPrice,
            nejnizsiCenaMnozstvi: tyreInfo.SPILowestPriceAmount,
          };
        }
        // Pro položky, pro které není nalezena žádná informace o pneumatice, necháme item nezměněn
        return item;
      });
  
      setData(updatedData); // Aktualizace stavu 'data' s novými informacemi
      setIsUpdating(false);
    } catch (error) {
      console.error('Error fetching tyre data:', error);
      setIsUpdating(false);
    }
  };

  const handleFilterChange = (filterName, filterValue) => {
    setFilterValues(prev => ({ ...prev, [filterName]: filterValue }));
  };

  return (
    <Box>
  
    <h2>Produkty pro B2B</h2>
    {
  isUpdating && <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px', zIndex: 1000 }}>Aktualizuji data, prosím čekejte...</div>
}
    <button onClick={saveDataWithFilterToImp} className="btn btn-success mb-3">Otisk výsledků</button>
    <button onClick={fetchTyreData} className="btn btn-primary mb-3">Načíst data z B2B</button>
    <button onClick={exportToXlsx} className="btn btn-success mb-3">Export do XLSX</button>
    <Paper >
    <TableContainer component={Paper} className="tableScrollBody" style={{ maxHeight: '70vh' }}>
  <Table stickyHeader aria-label="sticky table">
    <TableHead>
              <TableRow>
       
            <th>ID dodavatele <ColumnFilter filterName="PrimaryVendorId" initialValue={filterValues["PrimaryVendorId"]} onChange={value => handleFilterChange("PrimaryVendorId", value)} /></th>
            <th>Číslo položky <ColumnFilter filterName="ItemId" initialValue={filterValues["ItemId"]} onChange={value => handleFilterChange("ItemId", value)} /></th>
            <th>Výrobce <ColumnFilter filterName="ItsItemName3" initialValue={filterValues["ItsItemName3"]} onChange={value => handleFilterChange("ItsItemName3", value)} /></th>
            <th>Sk. řád. slevy <ColumnFilter filterName="PurchLineDisc" initialValue={filterValues["PurchLineDisc"]} onChange={value => handleFilterChange("PurchLineDisc", value)} /></th>
            <th>Název <ColumnFilter filterName="ItemName" initialValue={filterValues["ItemName"]} onChange={value => handleFilterChange("ItemName", value)} /></th>
            <th>Externí číslo položky <ColumnFilter filterName="ExternalName" initialValue={filterValues["ExternalName"]} onChange={value => handleFilterChange("ExternalName", value)} /></th>
            <th>Prodejní cena <ColumnFilter filterName="SalesPrice" initialValue={filterValues["SalesPrice"]} onChange={value => handleFilterChange("SalesPrice", value)} /></th>
            <th>V akci od <ColumnFilter filterName="ItsActionDateFrom" initialValue={filterValues["ItsActionDateFrom"]} onChange={value => handleFilterChange("ItsActionDateFrom", value)} /></th>
            <th>V akci do <ColumnFilter filterName="ItsActionDateTo" initialValue={filterValues["ItsActionDateTo"]} onChange={value => handleFilterChange("ItsActionDateTo", value)} /></th>
            <th>Akční cena <ColumnFilter filterName="ItsActionPrice" initialValue={filterValues["ItsActionPrice"]} onChange={value => handleFilterChange("ItsActionPrice", value)} /></th>
            <th>Marketingová akce <ColumnFilter filterName="ItsMarketingActionId" initialValue={filterValues["PrimaryVendorId"]} onChange={value => handleFilterChange("PrimaryVendorId", value)} /></th>
            <th>Dostupnost Web <ColumnFilter filterName="ItsWebAvailable" initialValue={filterValues["ItsWebAvailable"]} onChange={value => handleFilterChange("ItsWebAvailable", value)} /></th>
            <th>Dostupnost B2B <ColumnFilter filterName="ItsWebAvailableB2B" initialValue={filterValues["ItsWebAvailableB2B"]} onChange={value => handleFilterChange("ItsWebAvailableB2B", value)} /></th>
            <th>Skupina řádkové slevy <ColumnFilter filterName="ItemGroupId" initialValue={filterValues["ItemGroupId"]} onChange={value => handleFilterChange("ItemGroupId", value)} /></th>
            <th>Datum prodejní ceny <ColumnFilter filterName="SalesPriceDate" initialValue={filterValues["SalesPriceDate"]} onChange={value => handleFilterChange("SalesPriceDate", value)} /></th>
            <th>Nejnižší Cena B2B</th>
            <th>Množství B2B</th>
            <th>Rozdíl ceny</th>
            <th>Dostupné pro B2B</th>
            <th>Celkem PT</th>
            <th>Spárováno</th>
            </TableRow>
            </TableHead>
            <TableBody>
            {data.map((item, index) => {
 
 let priceDifference = "N/A"; // Inicializujeme rozdíl ceny jako "N/A"

 // Kontrola, zda je akční cena rovna 0
 if (item.ItsActionPrice === 0) {
   priceDifference = "Uprav akční cenu";
 }
 // Kontrola, zda nejnižší cena B2B a akční cena jsou dostupné a akční cena není rovna 0
 else if (item.nejnizsiCena !== null && item.ItsActionPrice !== null) {
   priceDifference = item.nejnizsiCena - item.ItsActionPrice;
 }
    return (
      <tr key={index}>
        <td>{item.PrimaryVendorId}</td>
        <td>{item.ItemId}</td>
        <td>{item.ItsItemName3}</td>
        <td>{item.PurchLineDisc}</td>
        <td>{item.ItemName}</td>
        <td>{item.ExternalName}</td>
        <td>{item.SalesPrice}</td>
        <td>{item.ItsActionDateFrom}</td>
        <td>{item.ItsActionDateTo}</td>
        <td>{item.ItsActionPrice}</td>
        <td>{item.ItsMarketingActionId}</td>
        <td>{item.ItsWebAvailable}</td>
        <td>{item.ItsWebAvailableB2B}</td>
        <td>{item.ItemGroupId}</td>
        <td>{item.SalesPriceDate}</td>
        <td>{item.nejnizsiCena}</td>
        <td>{item.nejnizsiCenaMnozstvi}</td>
        
        <td>{priceDifference !== "N/A" ? priceDifference : ""}</td>
        <td>{item.B2B_AvailableAmount ?? "N/A"}</td>
        <td>{item.celkemPT ?? "N/A"}</td>
        <td>
  {item.ID ? <a href={`https://pneub2b.eu/TyreDetail.aspx?id=${item.ID}`} target="_blank" rel="noopener noreferrer">Ano</a> : ""}
</td>

      </tr>
    );
  })}
</TableBody>


      </Table>
      </TableContainer>
    </Paper>
    </Box>
 
);
}

export default PolozkyB2B;