import React from 'react';
import tireImage from '../assets/tire.png'; // Ujistěte se, že cesta k obrázku je správná

const TyreButton = ({ label, sensorData, toggleTire, isActive, onContextMenu }) => {
  const isLeft = label.startsWith('L');
  const isOuter = parseInt(label[label.length - 1]) % 2 !== 0;
  const positionClass = `${isLeft ? 'left' : 'right'}-${isOuter ? 'outer' : 'inner'}`;
  const currentTime = new Date().getTime();
  const isNewerThanTenMinutes = sensorData && ((currentTime - sensorData.timestamp) / 60000 < 10);
  const backgroundStyle = isNewerThanTenMinutes ? { backgroundColor: 'green', color: 'white' } : {};
  
  return (
    <div className={`tyre-button-container ${positionClass}`} onContextMenu={(e) => onContextMenu(e, label)}>
    <div className={`tyre-info ${positionClass}`} style={backgroundStyle}> {/* Přidání stylu zde */}
      <div className="tyre-label" style={{ fontWeight: 'bold' }}>
        {label}
      </div>
      {sensorData && (
        <div className="tyre-pressure" style={{whiteSpace: 'nowrap'}}>
          {`${sensorData.pressure.toFixed(1)} b`}
        </div>
      )}
    </div>
  
      <button
        className={`tire-button ${isActive ? 'active' : ''}`}
        onClick={() => toggleTire(label)}
        style={{ backgroundImage: `url(${tireImage})` }}
      >
        {/* Obsah tlačítka */}
      </button>
    </div>
  );
};


export default TyreButton;