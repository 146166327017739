import React, { useState, useEffect, useRef } from 'react';
import ColumnFilter from './ColumnFilter';
import { useNavigate } from 'react-router-dom';

function ZakladniSlevy() {
  const [data, setData] = useState([]);
  const [editedData, setEditedData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newRowIndex, setNewRowIndex] = useState(null);
  const newRowRef = useRef(null);
  const [filterValues, setFilterValues] = useState({});
  const navigate = useNavigate();

  const handleEdit = (index, field, value) => {
    const newData = [...editedData];
    if (!newData[index]) {
      newData[index] = { ...data[index] };
    }
    newData[index][field] = value;
    setEditedData(newData);
  };

  const handleSave = () => {
    const filteredData = editedData.filter(item => item && item.cenove_skupiny !== '');
    const sanitizedData = filteredData.map(item => ({
      ...item,
      cenove_skupiny: item.cenove_skupiny.trim()
    }));

    fetch(process.env.REACT_APP_API_URL + '/update-data-zakladni-slevy', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(sanitizedData),
    })
      .then(response => {
        if (response.ok) {
          console.log('Data successfully updated.');
          fetchData();
        } else {
          throw new Error('Failed to update data.');
        }
      })
      .catch(error => {
        console.error('Error updating data:', error);
      });
  };

  const handleSaveRow = (index) => {
    const item = editedData[index];
    if (item && item.cenove_skupiny !== '') {
      const sanitizedData = {
        ...item,
        cenove_skupiny: item.cenove_skupiny.trim()
      };

      fetch(process.env.REACT_APP_API_URL + '/update-data-zakladni-slevy', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify([sanitizedData]),
      })
        .then(response => {
          if (response.ok) {
            console.log('Row successfully updated.');
            fetchData();
          } else {
            throw new Error('Failed to update row.');
          }
        })
        .catch(error => {
          console.error('Error updating row:', error);
        });
    }
  };

  const fetchData = () => {
    const queryParams = new URLSearchParams(filterValues).toString();

    fetch(`${process.env.REACT_APP_API_URL}/get-kalkulace-zakladni-slevy?${queryParams}`)
      .then(response => response.json())
      .then(data => {
        setData(data);
        setEditedData([]);
      })
      .catch(error => console.error('Error fetching data:', error));
  };

  useEffect(() => {
    fetchData();
  }, [filterValues]);

  useEffect(() => {
    if (newRowRef.current) {
      newRowRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      newRowRef.current.focus();
    }
  }, [data, newRowIndex]);

  const addRow = () => {
    handleSave();

    const newRow = {
      cenove_skupiny: '',
      jmeno: '',
      '1_eshop': '',
      '2_pult': '',
      '3_servis': '',
      '4_vo': '',
      '5_vip': '',
      '6_indiv': '',
      '7_dopravci': '',
      datum_zapsani: '',
      B2B: '',
      EXT_eshop: '',
      isNew: true
    };
    setData(prevData => [...prevData, newRow]);
    setEditedData(prevEditedData => {
      const newEditedData = prevEditedData.map((item, index) => (item ? { ...item, isNew: false } : item));
      return [...newEditedData, newRow];
    });
    setNewRowIndex(data.length);
    setIsModalOpen(true);
  };

  const removeRow = (index) => {
    const confirmed = window.confirm('Opravdu chcete odebrat tento řádek?');
    if (confirmed) {
      const cenove_skupiny = data[index].cenove_skupiny;

      fetch(`${process.env.REACT_APP_API_URL}/delete-data-zakladni-slevy/${cenove_skupiny}`, {
        method: 'DELETE',
      })
        .then(response => {
          if (response.ok) {
            const newData = data.filter((_, i) => i !== index);
            const newEditedData = editedData.filter((_, i) => i !== index);
            setData(newData);
            setEditedData(newEditedData);
          } else {
            throw new Error('Failed to delete item.');
          }
        })
        .catch(error => {
          console.error('Error deleting item:', error);
        });
    }
  };

  const handleFilterChange = (filterName, filterValue) => {
    setFilterValues(prev => ({ ...prev, [filterName]: filterValue }));
  };

  const isValueChanged = (index, field) => {
    return editedData[index] && editedData[index][field] !== undefined && editedData[index][field] !== data[index][field];
  };

  const modifiedStyle = { backgroundColor: 'yellow', padding: '0 5px' };

  return (
    <div className="card">
      <div className="card-header">
        <button className="btn btn-primary" onClick={handleSave}>Uložit změny</button>
        <button className="btn btn-secondary" onClick={addRow}>Přidat řádek</button>
        <h3 className="card-title">Základní Slevy</h3>
      </div>
      <div className="card-body">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Cenové Skupiny <ColumnFilter filterName="cenove_skupiny" initialValue={filterValues["cenove_skupiny"]} onChange={handleFilterChange} /></th>
              <th>Jméno <ColumnFilter filterName="jmeno" initialValue={filterValues["jmeno"]} onChange={handleFilterChange} /></th>
              <th>E-shop <ColumnFilter filterName="1_eshop" initialValue={filterValues["1_eshop"]} onChange={handleFilterChange} /></th>
              <th>Pult <ColumnFilter filterName="2_pult" initialValue={filterValues["2_pult"]} onChange={handleFilterChange} /></th>
              <th>Servis <ColumnFilter filterName="3_servis" initialValue={filterValues["3_servis"]} onChange={handleFilterChange} /></th>
              <th>VO <ColumnFilter filterName="4_vo" initialValue={filterValues["4_vo"]} onChange={handleFilterChange} /></th>
              <th>VIP <ColumnFilter filterName="5_vip" initialValue={filterValues["5_vip"]} onChange={handleFilterChange} /></th>
              <th>Indiv <ColumnFilter filterName="6_indiv" initialValue={filterValues["6_indiv"]} onChange={handleFilterChange} /></th>
              <th>Dopravci <ColumnFilter filterName="7_dopravci" initialValue={filterValues["7_dopravci"]} onChange={handleFilterChange} /></th>
              <th>Datum Zapsání</th>
              <th>B2B</th>
              <th>EXT E-shop</th>
              <th>Akce</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <tr key={index} ref={index === newRowIndex ? newRowRef : null}>
                <td
                  contentEditable
                  onBlur={e => handleEdit(index, 'cenove_skupiny', e.target.innerText.trim())}
                  style={isValueChanged(index, 'cenove_skupiny') ? modifiedStyle : null}
                >
                  {row.cenove_skupiny}
                </td>
                <td
                  contentEditable
                  onBlur={e => handleEdit(index, 'jmeno', e.target.innerText.trim())}
                  style={isValueChanged(index, 'jmeno') ? modifiedStyle : null}
                >
                  {row.jmeno}
                </td>
                {['1_eshop', '2_pult', '3_servis', '4_vo', '5_vip', '6_indiv', '7_dopravci', 'B2B', 'EXT_eshop'].map(field => (
                  <td
                    key={field}
                    contentEditable
                    onBlur={e => handleEdit(index, field, e.target.innerText)}
                    style={isValueChanged(index, field) ? modifiedStyle : null}
                  >
                    {row[field]}
                  </td>
                ))}
                <td>{row.datum_zapsani}</td>
                <td>{row.B2B}</td>
                <td>{row.EXT_eshop}</td>
                <td>
                  <button className="btn btn-danger" onClick={() => removeRow(index)}>Odebrat</button>
                  {isValueChanged(index, 'cenove_skupiny') && (
                    <button className="btn btn-success" onClick={() => handleSaveRow(index)}>Uložit</button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ZakladniSlevy;
