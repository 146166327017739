// ServiceTasksSection.js
import React from 'react';

const ServiceTasksSection = ({ serviceTasks, updateTaskCount, updateNote, toggleNoteActive }) => {
  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">Úkony Servisu</h3>
      </div>
      <div className="card-body">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>Úkon</th>
                <th>Počet</th>
                <th>Typ</th>
                <th>Pozn.</th>
              </tr>
            </thead>
            <tbody>
              {serviceTasks.map(task => (
                <>
                  <tr key={task.id}>
                    <td>{task.name}</td>
<td className="count-control">
  <button className="btn btn-secondary count-btn" onClick={() => updateTaskCount(task.id, task.count - 1)}>-</button>
  <input
    type="number"
    className="form-control task-count-input"
    value={task.count}
    onChange={e => updateTaskCount(task.id, parseInt(e.target.value) || 0)}
  />
  <button className="btn btn-secondary count-btn" onClick={() => updateTaskCount(task.id, task.count + 1)}>+</button>
</td>
                    <td>{task.type}</td>
                    <td>
                      <input
                        type="checkbox"
                        checked={task.noteActive}
                        onChange={() => toggleNoteActive(task.id)}
                      />
                    </td>
                  </tr>
                  {task.noteActive && (
                    <tr key={`note-${task.id}`}>
                      <td colSpan="4">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Poznámka"
                          value={task.note}
                          onChange={e => updateNote(task.id, e.target.value)}
                        />
                      </td>
                    </tr>
                  )}
                </>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ServiceTasksSection;
