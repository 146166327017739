import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiBox: {
      styleOverrides: {
        root: {
          // Globální styly pro všechny Box komponenty
          padding: '100px',
          backgroundColor: 'lightblue',
        },
      },
    },
  },
});

export default theme;
