import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Collapse, IconButton, Toolbar } from '@mui/material';
import { ExpandLess, ExpandMore, CarRepair, CalendarToday, DirectionsCar, Settings, SyncAlt, BarChart, ImportExport, Calculate, ShoppingCart, PriceChange, Sale, BusinessCenter } from '@mui/icons-material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

function LeftMenu({ open, handleDrawerToggle }) {
  const [isServiceMenuCollapsed, setIsServiceMenuCollapsed] = useState(false);
  const [isAnalysisMenuCollapsed, setIsAnalysisMenuCollapsed] = useState(false);
  const [isPriceMenuCollapsed, setIsPriceMenuCollapsed] = useState(false);
  const [isB2BMenuCollapsed, setIsB2BMenuCollapsed] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const toggleServiceMenu = () => {
    setIsServiceMenuCollapsed(!isServiceMenuCollapsed);
  };

  const toggleAnalysisMenu = () => {
    setIsAnalysisMenuCollapsed(!isAnalysisMenuCollapsed);
  };
  const togglePriceMenu = () => {
    setIsPriceMenuCollapsed(!isPriceMenuCollapsed);
  };
  const toggleB2BMenu = () => {
    setIsB2BMenuCollapsed(!isB2BMenuCollapsed);
  };

  return (
    <Drawer
      variant={isMobile ? 'temporary' : 'persistent'}
      open={open}
      onClose={handleDrawerToggle}
      sx={{
        width: 240,
        flexShrink: 0,
        '& .MuiDrawer-paper': { width: 240, boxSizing: 'border-box' },
      }}
    >
      <Toolbar />
      <IconButton onClick={handleDrawerToggle}>
        <ChevronLeftIcon />
      </IconButton>
      <List>
        <ListItem button onClick={toggleServiceMenu}>
          <ListItemIcon>
            <CarRepair />
          </ListItemIcon>
          <ListItemText primary="Servis" />
          {isServiceMenuCollapsed ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={isServiceMenuCollapsed} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button component={Link} to="/servicesheet">
              <ListItemText inset primary="Nákladní servis" />
            </ListItem>
            <ListItem button component={Link} to="/calendar">
              <ListItemText inset primary="Kalendář" />
            </ListItem>
            <ListItem button component={Link} to="/vehicle-viewer">
              <ListItemText inset primary="Správa vozidel" />
            </ListItem>
            <ListItem button component={Link} to="/vehicle-templates">
              <ListItemText inset primary="Šablony vozidel" />
            </ListItem>
            <ListItem button component={Link} to="/serviceconfig">
              <ListItemText inset primary="Konfigurace Servis" />
            </ListItem>
          </List>
        </Collapse>
        <ListItem button onClick={toggleAnalysisMenu}>
          <ListItemIcon>
            <BarChart /> {/* Změněno na ikonu pro Analýzu */}
          </ListItemIcon>
          <ListItemText primary="Analýza" />
          {isAnalysisMenuCollapsed ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={isAnalysisMenuCollapsed} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button component={Link} to="/ax-live-products">
              <ListItemText inset primary="Položky AX" />
            </ListItem>
            <ListItem button component={Link} to="/import-plor">
              <ListItemText inset primary="Import kalkulačního ceníku" />
            </ListItem>
            <ListItem button component={Link} to="/polozkyb2b">
              <ListItemText inset primary="Položky B2B" />
            </ListItem>
            </List>
        </Collapse>
        <ListItem button onClick={togglePriceMenu}>
          <ListItemIcon>
            <BarChart /> {/* Změněno na ikonu pro Analýzu */}
          </ListItemIcon>
          <ListItemText primary="Ceny" />
          {isPriceMenuCollapsed ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={isPriceMenuCollapsed} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          <ListItem button component={Link} to="/ProductList">
              <ListItemText inset primary="Seznam produktů" />
            </ListItem>
            <ListItem button component={Link} to="/zakladni_slevy">
              <ListItemText inset primary="Základní slevy" />
            </ListItem>
            <ListItem button component={Link} to="/Netto">
              <ListItemText inset primary="Netto ceny" />
            </ListItem>
            <ListItem button component={Link} to="/akce_polozka">
              <ListItemText inset primary="Akce položka" />
            </ListItem>
            <ListItem button component={Link} to="/Vyprodej">
              <ListItemText inset primary="Výprodej" />
            </ListItem>
           </List>
        </Collapse>
        <ListItem button onClick={toggleB2BMenu}>
          <ListItemIcon>
            <BarChart /> {/* Změněno na ikonu pro Analýzu */}
          </ListItemIcon>
          <ListItemText primary="B2B" />
          {isB2BMenuCollapsed ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={isB2BMenuCollapsed} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button component={Link} to="/objednavky-b2b">
              <ListItemText inset primary="Objednávky B2B" />
            </ListItem>
            <ListItem button component={Link} to="/polozkyb2b">
              <ListItemText inset primary="Položky B2B" />
            </ListItem>
            <ListItem button component={Link} to="/se_orderlist">
              <ListItemText inset primary="Objednávky SE" />
            </ListItem>

          </List>
        </Collapse>
        {/* Přidání dalších sekci podle potřeby */}
      </List>
    </Drawer>
  );
}

export default LeftMenu;
