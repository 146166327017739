import React, { useState, useEffect } from 'react';
import axios from 'axios';
import TyreButton from './TyreButton';
import '../css/TyreSection.css';
import HNImage from '../assets/HN.png';
import HRImage from '../assets/HR.png';
import NNImage from '../assets/NN.png';
import NRImage from '../assets/NR.png';

// Mapování typů vozidel na sekce, které mají být zobrazeny
const vehicleTypeMap = {
  T: { sections: ['P', 'Z', 'R'], description: "Tahač" },
  V: { sections: ['T', 'R'], description: "Vlek" },
  N: { sections: ['T', 'R'], description: "Návěs" },
  P: { sections: ['T', 'R'], description: "Přívěs" },
  Z: { sections: ['P', 'Z', 'T', 'R'], description: "Základní typ" },
  O: { sections: ['P', 'Z', 'R'], description: "Osobní vozidlo"},
  D: { sections: ['P', 'Z', 'R'], description: "Dodávka"}
};
const renderSensorDataTable = (sensorsDataMap) => {
  const sensorsDataArray = Object.values(sensorsDataMap);
  if (sensorsDataArray.length === 0) {
    return null;
  }
  return (
    <div className="sensors-data-table">
      <div className="table-responsive">
        <table className="table table-striped table-hover">
          <thead className="thead-light">
            <tr>
              <th>Pozice</th>
              <th>Lokace</th>
              <th>Timestamp</th>
              <th>Kód čipu</th>
              <th>Tlak</th>
              <th>Teplota</th>
              <th>Baterie %</th>
              <th>Únik</th>
            </tr>
          </thead>
          <tbody>
            {sensorsDataArray.map(sensor => (
              <tr key={sensor.id}>
                <td>{sensor.position}</td>
                <td>{sensor.locationId}</td>
                <td>{sensor.formatted_timestamp}</td>
                <td>{sensor.macAddress}</td>
                <td>{sensor.pressure.toFixed(2)} bar</td>
                <td>{sensor.temperature.toFixed(2)} °C</td>
                <td>{sensor.batteryPercentage}%</td>
                <td>{sensor.leaking === 0 ? 'Ne' : 'Ano'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
const TyreSection = ({ templateId, title = "", RZ, refreshKey }) => {
  const [axles, setAxles] = useState([]);
  const [vehicleType, setVehicleType] = useState('');
  const [reserveCount, setReserveCount] = useState(0);
  const [sensorsDataMap, setSensorsDataMap] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      if (!templateId) return;
      
      try {
        const url = RZ 
          ? `${process.env.REACT_APP_API_URL}/templates/${templateId}/${RZ}`
          : `${process.env.REACT_APP_API_URL}/templates/${templateId}`;
        
        const response = await axios.get(url);
        const { templateDetails, sensorsData } = response.data;
  
        if (templateDetails && Array.isArray(templateDetails) && templateDetails.length > 0) {
          setAxles(templateDetails);
          setVehicleType(templateDetails[0].Type);
          setReserveCount(templateDetails[0].reserveCount);
        } else {
          console.error('Data načtená z API nejsou ve správném formátu:', templateDetails);
        }
  
        if (sensorsData && Array.isArray(sensorsData)) {
          const sensorsMap = sensorsData.reduce((map, sensor) => {
            map[sensor.position] = sensor;
            return map;
          }, {});
          setSensorsDataMap(sensorsMap);
        } else {
          console.error('Data z čidel načtená z API nejsou ve správném formátu:', sensorsData);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, [templateId, RZ, refreshKey]); // Zahrnutí refreshKey do seznamu závislostí pro možnost vynuceného obnovení
  

  const getAxleImageSrc = (axleType, driveType) => {
    if (driveType === 'H' && axleType === 'N') return HNImage;
    if (driveType === 'H' && axleType === 'R') return HRImage;
    if (driveType === 'N' && axleType === 'N') return NNImage;
    if (driveType === 'N' && axleType === 'R') return NRImage;
    return null;
  };
  const generateReserveLabels = (reserveCount) => {
    const labels = [];
    for (let i = 1; i <= reserveCount; i++) {
      labels.push(`R${i}`);
    }
    return labels;
  };
  const generateTyreLabels = (axle, axleIndex, totalAxles) => {
    let labels = [];
    let tyresBeforeThisAxle = 0;
  
    for (let i = 0; i < axleIndex; i++) {
      // Vzhledem k tomu, že pro každou sekci je specifická logika, předpokládá se, že počet pneumatik před touto nápravou se může lišit
      tyresBeforeThisAxle += (axles[i].mountType === '2' ? 4 : 2);
    }
  
    const baseIndex = 1 + tyresBeforeThisAxle;
    const isSingleMount = axle.mountType === '1';
    // Neměníme logiku pro určení, jestli je jedno nebo dvojmontáž, protože to není součástí dotazu
  
    // Sekce "P"
    if (axle.section === 'P') {
      // Pro sekci "P" generujeme jednoduše LP1, PP1, atd. pro každou nápravu bez ohledu na počet pneumatik před ní
      const indexForP = axleIndex + 1; // Index založený přímo na pořadí nápravy v sekci
      labels.push(`LP${indexForP}`, `PP${indexForP}`);
    }
    // Sekce "Z"
    else if (axle.section === 'Z') {
      // Logika zůstává stejná jako v původním dotazu
      let prefix = 'Z'; // Předpona pro sekci "Z"
      if (isSingleMount) {
        labels.push(`L${prefix}${baseIndex}`, `P${prefix}${baseIndex}`);
      } else {
        labels.push(`L${prefix}${baseIndex}`, `L${prefix}${baseIndex + 1}`);
        labels.push(`P${prefix}${baseIndex + 1}`, `P${prefix}${baseIndex}`);
      }
    }
    // Sekce "T" (zde jsem předpokládal, že je třeba nějaká specifická logika, ale nebyla detailně specifikována)
    else if (axle.section === 'T') {
      // V případě potřeby zde upravte logiku pro sekci "T" podle konkrétních požadavků
      const indexForT = axleIndex + 1; // Možný přístup pro jednomontáže v sekci "T"
      labels.push(`L${indexForT}`, `P${indexForT}`);
    }
    const labelsWithSensors = labels.map(label => {
      const sensorData = sensorsDataMap[label];
      return { label, sensorData }; // Přidáváte sensorData k popisku, pokud existují
    });
  
    return labelsWithSensors;
  };
    const sectionsToDisplay = vehicleTypeMap[vehicleType]?.sections || [];
  // Funkce pro vykreslení tabulky s daty čidel
  const renderSensorDataTable = (sensorsData) => (
    <div className="sensors-data-table">
  <div className="table-responsive">
    <table className="table table-striped table-hover">
      <thead className="thead-light">
        <tr>
          <th>Pozice</th>
          <th>Lokace</th>
          <th>Timestamp</th>
          <th>Kód čipu</th>
          <th>Tlak</th>
          <th>Teplota</th>
          <th>Baterie %</th>
          <th>Únik</th>
        </tr>
      </thead>
      <tbody>
        {sensorsData.map(sensor => (
          <tr key={sensor.id}>
            <td>{sensor.position}</td>
            <td>{sensor.locationId}</td>
            <td>{sensor.formatted_timestamp}</td>
            <td>{sensor.macAddress}</td>
            <td>{sensor.pressure.toFixed(2)} bar</td>
            <td>{sensor.temperature.toFixed(2)} °C</td>
            <td>{sensor.batteryPercentage}%</td>
            <td>{sensor.leaking === 0 ? 'Ne' : 'Ano'}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
</div>

  );
  return (
    <div className="tyre-section d-flex flex-column">
      {title && <h2>{title}</h2>}
      {sectionsToDisplay.map((section, sectionIndex) => (
        <div key={section} className={`${section}-axles`}>
          {axles
            .filter(axle => axle.section === section)
            .map((axle, axleIndex) => {
              const labels = generateTyreLabels(axle, axleIndex, axles); // Upravte podle potřeby
              return (
                <div key={axleIndex} className="axle-container">
                  <div className="tyres tyres-left" style={{ justifyContent: 'flex-end' }}>
                    {labels.filter(({ label }) => label.startsWith('L'))
                      .map(({ label, sensorData }, tyreIndex) => (
                        <TyreButton 
                          key={`left-${tyreIndex}`} 
                          label={label} 
                          sensorData={sensorData}
                        />
                    ))}
                  </div>
                  <img src={getAxleImageSrc(axle.axleType, axle.driveType)} alt={`${axle.axleType}${axle.driveType} Axle`} className="axle-image" />
                  <div className="tyres tyres-right" style={{ justifyContent: 'flex-start' }}>
                    {labels.filter(({ label }) => label.startsWith('P'))
                      .map(({ label, sensorData }, tyreIndex) => (
                        <TyreButton 
                          key={`right-${tyreIndex}`} 
                          label={label} 
                          sensorData={sensorData}
                        />
                    ))}
                  </div>
                </div>
              );
            })}
        </div>
      ))}
      <div className="reserve-axles d-flex">
        {generateReserveLabels(reserveCount).map((label, index) => (
          <TyreButton key={`reserve-${index}`} label={label} />
        ))}
      </div>
      {/* Vykreslení tabulky s daty čidel */}
      {Object.values(sensorsDataMap).length > 0 && renderSensorDataTable(Object.values(sensorsDataMap))}
    </div>
  );
}

export default TyreSection;