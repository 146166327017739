import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function Login({ setIsAuthenticated }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    const response = await fetch(process.env.REACT_APP_API_URL + '/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password }),
    });

    if (response.ok) {
      const data = await response.json();
      localStorage.setItem('authToken', data.token);
      setIsAuthenticated(true);
    } else {
      setError('Nesprávné uživatelské jméno nebo heslo');
    }
  };

  return (
    <div className="login-box">
      <div className="login-logo">
        <b>Vaše</b>Logo
      </div>
      <div className="card">
        <div className="card-body login-card-body">
          <p className="login-box-msg">Přihlaste se pro zahájení relace</p>

          {error && <div className="alert alert-danger" role="alert">{error}</div>}

          <form onSubmit={handleSubmit}>
            <div className="input-group mb-3">
              <input type="text" className="form-control" placeholder="Uživatelské jméno"
                value={username} onChange={(e) => setUsername(e.target.value)} required />
              <div className="input-group-append">
                <div className="input-group-text">
                  <span className="fas fa-user"></span>
                </div>
              </div>
            </div>
            <div className="input-group mb-3">
              <input type="password" className="form-control" placeholder="Heslo"
                value={password} onChange={(e) => setPassword(e.target.value)} required />
              <div className="input-group-append">
                <div className="input-group-text">
                  <span className="fas fa-lock"></span>
                </div>
              </div>
            </div>
            <button type="submit" className="btn btn-primary btn-block">Přihlásit</button>
          </form>

        </div>
      </div>
    </div>
  );
}

export default Login;
