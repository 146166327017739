import React, { useState, useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format, parseISO } from 'date-fns';
import SearchProductModal from './SearchProductModal';
import ColumnFilter from './ColumnFilter';
import { useNavigate } from 'react-router-dom';

function AkcePolozka() {
  const [data, setData] = useState([]);
  const [editedData, setEditedData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newRowIndex, setNewRowIndex] = useState(null);
  const newRowRef = useRef(null);
  const [filterValues, setFilterValues] = useState({});
  const [dateFilter, setDateFilter] = useState('all');
  const navigate = useNavigate();

  const handleEdit = (index, field, value) => {
    const newData = [...editedData];
    if (!newData[index]) {
      newData[index] = { ...data[index] };
    }
    newData[index][field] = value;
    setEditedData(newData);
  };

  const handleDateChange = (index, field, value) => {
    if (value instanceof Date) {
      const formattedDate = format(value, "yyyy-MM-dd");
      handleEdit(index, field, formattedDate);
    } else {
      handleEdit(index, field, null);
    }
  };

  const handleSave = () => {
    const filteredData = editedData.filter(item => item && item.polozka !== '');
    const sanitizedData = filteredData.map(item => ({
      ...item,
      polozka: item.polozka.trim()
    }));

    fetch(process.env.REACT_APP_API_URL + '/update-data-akcepolozka', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(sanitizedData),
    })
      .then(response => {
        if (response.ok) {
          console.log('Data successfully updated.');
          fetchData();
        } else {
          throw new Error('Failed to update data.');
        }
      })
      .catch(error => {
        console.error('Error updating data:', error);
      });
  };

  const handleSaveRow = (index) => {
    const item = editedData[index];
    if (item && item.polozka !== '') {
      const sanitizedData = {
        ...item,
        polozka: item.polozka.trim()
      };

      fetch(process.env.REACT_APP_API_URL + '/update-data-akcepolozka', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify([sanitizedData]),
      })
        .then(response => {
          if (response.ok) {
            console.log('Row successfully updated.');
            fetchData();
          } else {
            throw new Error('Failed to update row.');
          }
        })
        .catch(error => {
          console.error('Error updating row:', error);
        });
    }
  };

  const fetchData = () => {
    const queryParams = new URLSearchParams(filterValues).toString();

    fetch(`${process.env.REACT_APP_API_URL}/get-kalkulace-cen-akcepolozka?${queryParams}`)
      .then(response => response.json())
      .then(data => {
        const tzData = data.map(item => ({
          ...item,
          platnost_od: item.platnost_od ? format(parseISO(item.platnost_od), 'yyyy-MM-dd') : null,
          platnost_do: item.platnost_do ? format(parseISO(item.platnost_do), 'yyyy-MM-dd') : null,
        }));
        setData(tzData);
        setEditedData([]);
      })
      .catch(error => console.error('Error fetching data:', error));
  };

  useEffect(() => {
    fetchData();
  }, [filterValues]);

  useEffect(() => {
    if (newRowRef.current) {
      newRowRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      newRowRef.current.focus();
    }
  }, [data, newRowIndex]);

  const addRow = () => {
    handleSave();

    const newRow = {
      polozka: '',
      Name: '',
      '1_eshop': '',
      '2_pult': '',
      '3_servis': '',
      '4_vo': '',
      '5_vip': '',
      '6_indiv': '',
      '7_dopravci': '',
      platnost_od: null,
      platnost_do: null,
      marze: '',
      datum_zapsani: '',
      zapsal: '',
      isNew: true
    };
    setData(prevData => [...prevData, newRow]);
    setEditedData(prevEditedData => {
      const newEditedData = prevEditedData.map((item, index) => (item ? { ...item, isNew: false } : item));
      return [...newEditedData, newRow];
    });
    setNewRowIndex(data.length);
    setIsModalOpen(true);
  };

  const removeRow = (index) => {
    const confirmed = window.confirm('Opravdu chcete odebrat tento řádek?');
    if (confirmed) {
      const polozka = data[index].polozka;

      fetch(`${process.env.REACT_APP_API_URL}/delete-data-akcepolozka/${polozka}`, {
        method: 'DELETE',
      })
        .then(response => {
          if (response.ok) {
            const newData = data.filter((_, i) => i !== index);
            const newEditedData = editedData.filter((_, i) => i !== index);
            setData(newData);
            setEditedData(newEditedData);
          } else {
            throw new Error('Failed to delete item.');
          }
        })
        .catch(error => {
          console.error('Error deleting item:', error);
        });
    }
  };

  const handleSelectItem = (item) => {
    if (newRowIndex !== null) {
      const newData = [...data];
      if (!newData[newRowIndex]) {
        newData[newRowIndex] = {};
      }
      newData[newRowIndex].polozka = item.ItemId;
      newData[newRowIndex].Name = item.ItemName;
      newData[newRowIndex].isNew = true;
      setData(newData);
      setIsModalOpen(false);
      setTimeout(() => {
        if (newRowRef.current) {
          newRowRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
          newRowRef.current.focus();
        }
      }, 100);
    }
  };

  const handleProductClick = (polozka) => {
    navigate(`/productdetail/${polozka}`);
  };

  const getColorForDateValidity = (row, index) => {
    if (index === newRowIndex) {
      return 'table-warning';
    }
    const today = new Date();
    const startDate = row.platnost_od ? parseISO(row.platnost_od) : null;
    const endDate = row.platnost_do ? parseISO(row.platnost_do) : null;

    if (!startDate || !endDate) {
      return 'table-danger';
    }

    const timeLeft = (endDate - today) / (1000 * 3600 * 24);

    if (today >= startDate && today <= endDate) {
      if (timeLeft <= 7) {
        return 'table-warning';
      }
      return 'table-success';
    }
    return 'table-danger';
  };

  const modifiedStyle = { backgroundColor: 'yellow', padding: '0 5px' };

  const isValueChanged = (index, field) => {
    return editedData[index] && editedData[index][field] !== undefined && editedData[index][field] !== data[index][field];
  };

  const handleKeyPress = (event, index, field) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleEdit(index, field, event.target.innerText);
      event.target.blur();
    }
  };

  const handleFilterChange = (filterName, filterValue) => {
    setFilterValues(prev => ({ ...prev, [filterName]: filterValue }));
  };

  const handleDateFilterChange = (filter) => {
    setDateFilter(filter);
  };

  const filteredData = data.filter((row) => {
    const today = new Date();
    const startDate = row.platnost_od ? parseISO(row.platnost_od) : null;
    const endDate = row.platnost_do ? parseISO(row.platnost_do) : null;

    if (dateFilter === 'valid') {
      return startDate && endDate && today >= startDate && today <= endDate;
    } else if (dateFilter === 'expiring') {
      const timeLeft = endDate ? (endDate - today) / (1000 * 3600 * 24) : null;
      return startDate && endDate && today >= startDate && today <= endDate && timeLeft <= 7;
    } else if (dateFilter === 'invalid') {
      return !startDate || !endDate || today < startDate || today > endDate;
    } else {
      return true;
    }
  });

  return (
    <div className="card">
      <div className="card-header">
        <button className="btn btn-primary" onClick={handleSave}>Uložit změny</button>
        <button className="btn btn-secondary" onClick={addRow}>Přidat řádek</button>
        <h3 className="card-title">Akce položka</h3>
        <div className="date-filter">
          <button className={`btn btn-outline-primary ${dateFilter === 'all' ? 'active' : ''}`} onClick={() => handleDateFilterChange('all')}>Vše</button>
          <button className={`btn btn-outline-success ${dateFilter === 'valid' ? 'active' : ''}`} onClick={() => handleDateFilterChange('valid')}>Platné</button>
          <button className={`btn btn-outline-warning ${dateFilter === 'expiring' ? 'active' : ''}`} onClick={() => handleDateFilterChange('expiring')}>Končící</button>
          <button className={`btn btn-outline-danger ${dateFilter === 'invalid' ? 'active' : ''}`} onClick={() => handleDateFilterChange('invalid')}>Neplatné</button>
        </div>
      </div>
      <div className="card-body">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Položka <ColumnFilter filterName="polozka" initialValue={filterValues["polozka"]} onChange={handleFilterChange} /></th>
              <th>Název <ColumnFilter filterName="Name" initialValue={filterValues["Name"]} onChange={handleFilterChange} /></th>
              <th>E-shop <ColumnFilter filterName="1_eshop" initialValue={filterValues["1_eshop"]} onChange={handleFilterChange} /></th>
              <th>Pult <ColumnFilter filterName="2_pult" initialValue={filterValues["2_pult"]} onChange={handleFilterChange} /></th>
              <th>Servis <ColumnFilter filterName="3_servis" initialValue={filterValues["3_servis"]} onChange={handleFilterChange} /></th>
              <th>VO <ColumnFilter filterName="4_vo" initialValue={filterValues["4_vo"]} onChange={handleFilterChange} /></th>
              <th>VIP <ColumnFilter filterName="5_vip" initialValue={filterValues["5_vip"]} onChange={handleFilterChange} /></th>
              <th>Indiv <ColumnFilter filterName="6_indiv" initialValue={filterValues["6_indiv"]} onChange={handleFilterChange} /></th>
              <th>Dopravci <ColumnFilter filterName="7_dopravci" initialValue={filterValues["7_dopravci"]} onChange={handleFilterChange} /></th>
              <th>B2B</th>
              <th>EXT E-shop</th>
              <th>Platnost od</th>
              <th>Platnost do</th>
              <th>Marže</th>
              <th>Datum Zapsání</th>
              <th>Zapsal</th>
              <th>Akce</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((row, index) => (
              <tr key={index} className={getColorForDateValidity(row, index)} ref={index === newRowIndex ? newRowRef : null}>
                <td
                  contentEditable
                  onBlur={e => handleEdit(index, 'polozka', e.target.innerText.trim())}
                  style={isValueChanged(index, 'polozka') ? modifiedStyle : null}
                  onClick={() => handleProductClick(row.polozka)}
                >
                  {row.polozka}
                </td>
                <td>{row.Name}</td>
                {['1_eshop', '2_pult', '3_servis', '4_vo', '5_vip', '6_indiv', '7_dopravci', 'B2B', 'EXT_eshop'].map(field => (
                  <td
                    key={field}
                    contentEditable
                    onBlur={e => handleEdit(index, field, e.target.innerText)}
                    onKeyDown={e => handleKeyPress(e, index, field)}
                    style={isValueChanged(index, field) ? modifiedStyle : null}
                  >
                    {row[field]}
                  </td>
                ))}
                <td>
                  <DatePicker
                    selected={editedData[index] && editedData[index].platnost_od ? new Date(editedData[index].platnost_od) : row.platnost_od ? new Date(row.platnost_od) : null}
                    onChange={date => handleDateChange(index, 'platnost_od', date)}
                    dateFormat="yyyy-MM-dd"
                  />
                </td>
                <td>
                  <DatePicker
                    selected={editedData[index] && editedData[index].platnost_do ? new Date(editedData[index].platnost_do) : row.platnost_do ? new Date(row.platnost_do) : null}
                    onChange={date => handleDateChange(index, 'platnost_do', date)}
                    dateFormat="yyyy-MM-dd"
                  />
                </td>
                <td>{row.marze}</td>
                <td>{row.datum_zapsani}</td>
                <td>{row.zapsal}</td>
                <td>
                  <button className="btn btn-danger" onClick={() => removeRow(index)}>Odebrat</button>
                  {isValueChanged(index, 'polozka') && (
                    <button className="btn btn-success" onClick={() => handleSaveRow(index)}>Uložit</button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {isModalOpen && (
        <SearchProductModal 
          isOpen={isModalOpen} 
          onClose={() => setIsModalOpen(false)} 
          onSelectItem={handleSelectItem}
        />
      )}
    </div>
  );
}

export default AkcePolozka;
