import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { TextField, IconButton, Menu, MenuItem, ListItemText } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';

function ColumnFilter({ filterName, initialValue, onChange }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterValue, setFilterValue] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const paramValue = searchParams.get(filterName);
    if (paramValue) {
      setFilterValue(paramValue);
    }
  }, [filterName, location.search]);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSearch = (value) => {
    const searchParams = new URLSearchParams(location.search);
    if (value) {
      searchParams.set(filterName, value);
    } else {
      searchParams.delete(filterName);
    }
    navigate(`${location.pathname}?${searchParams.toString()}`);
    onChange(filterName, value);
    handleMenuClose();
  };

  return (
    <div>
      <IconButton onClick={handleMenuClick}>
        <FilterListIcon />
      </IconButton>
      <Menu
        id="filter-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem>
          <ListItemText>
            <TextField
              size="small"
              label="Filtr"
              value={filterValue}
              onChange={(e) => setFilterValue(e.target.value)}
              onKeyDown={(e) => e.key === 'Enter' && handleSearch(e.target.value)}
            />
          </ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
}

export default ColumnFilter;
