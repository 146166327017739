import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ColumnFilter from './ColumnFilter';
import { Box, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, ButtonGroup, Button } from '@mui/material';

function ProductList() {
  const [data, setData] = useState([]);
  const [filterValues, setFilterValues] = useState({});
  const [activeRuleFilter, setActiveRuleFilter] = useState('all');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const searchParams = new URLSearchParams(filterValues);
        searchParams.append('activeRuleFilter', activeRuleFilter); // Přidání aktivního filtru pravidel
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/productlist`, { params: Object.fromEntries(searchParams) });
        setData(response.data);
      } catch (error) {
        console.error('Error loading data:', error);
      }
    };

    fetchData();
  }, [filterValues, activeRuleFilter]);

  const handleFilterChange = (filterName, filterValue) => {
    setFilterValues(prev => ({ ...prev, [filterName]: filterValue }));
  };

  const handleRuleFilterChange = (rule) => {
    setActiveRuleFilter(rule);
  };

  return (
    <Box height="100vh" display="flex" flexDirection="column">
      <h2>Seznam produktů</h2>
      <ButtonGroup variant="contained" aria-label="outlined primary button group">
        <Button onClick={() => handleRuleFilterChange('all')} variant={activeRuleFilter === 'all' ? 'contained' : 'outlined'}>Vše</Button>
        <Button onClick={() => handleRuleFilterChange('zakladni_slevy')} variant={activeRuleFilter === 'zakladni_slevy' ? 'contained' : 'outlined'}>Základní sleva</Button>
        <Button onClick={() => handleRuleFilterChange('netto')} variant={activeRuleFilter === 'netto' ? 'contained' : 'outlined'}>Netto</Button>
        <Button onClick={() => handleRuleFilterChange('akce_polozka')} variant={activeRuleFilter === 'akce_polozka' ? 'contained' : 'outlined'}>Akce Položka</Button>
        <Button onClick={() => handleRuleFilterChange('vyprodej')} variant={activeRuleFilter === 'vyprodej' ? 'contained' : 'outlined'}>Výprodej</Button>
      </ButtonGroup>
      <Paper style={{ flex: 1 }}>
        <TableContainer style={{ maxHeight: '100%' }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>Číslo položky <ColumnFilter filterName="ItemId" initialValue={filterValues["ItemId"]} onChange={handleFilterChange} /></TableCell>
                <TableCell>Výrobce <ColumnFilter filterName="ItsItemName3" initialValue={filterValues["ItsItemName3"]} onChange={handleFilterChange} /></TableCell>
                <TableCell>Sk. řád. slevy <ColumnFilter filterName="PurchLineDisc" initialValue={filterValues["PurchLineDisc"]} onChange={handleFilterChange} /></TableCell>
                <TableCell>Název <ColumnFilter filterName="ItemName" initialValue={filterValues["ItemName"]} onChange={handleFilterChange} /></TableCell>
                <TableCell>Prodejní cena <ColumnFilter filterName="SalesPrice" initialValue={filterValues["SalesPrice"]} onChange={handleFilterChange} /></TableCell>
                <TableCell>Aktivní pravidlo</TableCell>
                <TableCell>1_eshop</TableCell>
                <TableCell>2_pult</TableCell>
                <TableCell>3_servis</TableCell>
                <TableCell>4_vo</TableCell>
                <TableCell>5_vip</TableCell>
                <TableCell>6_indiv</TableCell>
                <TableCell>7_dopravci</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.ItemId}</TableCell>
                  <TableCell>{item.ItsItemName3}</TableCell>
                  <TableCell>{item.PurchLineDisc}</TableCell>
                  <TableCell>{item.ItemName}</TableCell>
                  <TableCell>{item.SalesPrice}</TableCell>
                  <TableCell>{item.aktivni_pravidlo}</TableCell>
                  <TableCell>{item['1_eshop']}</TableCell>
                  <TableCell>{item['2_pult']}</TableCell>
                  <TableCell>{item['3_servis']}</TableCell>
                  <TableCell>{item['4_vo']}</TableCell>
                  <TableCell>{item['5_vip']}</TableCell>
                  <TableCell>{item['6_indiv']}</TableCell>
                  <TableCell>{item['7_dopravci']}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}

export default ProductList;
