import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Box, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import ColumnFilter from './ColumnFilter'; // Importuje filtr pro sloupce

function SearchProductModal({ isOpen, onClose, onSelectItem }) {
  const [data, setData] = useState([]);
  const [filterValues, setFilterValues] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/items`);
        setData(response.data);
      } catch (error) {
        console.error('Error loading data:', error);
      }
    };

    fetchData();
  }, []);

  const handleFilterChange = (filterName, filterValue) => {
    setFilterValues(prev => ({ ...prev, [filterName]: filterValue }));
  };

  const filteredData = data.filter(item => {
    return Object.keys(filterValues).every(key => {
      const value = filterValues[key];
      if (!value) return true;
      return item[key]?.toString().toLowerCase().includes(value.toLowerCase());
    });
  });

  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal show d-block" tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: '80%' }}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Vyhledávání produktu</h5>
            <button type="button" className="close" onClick={onClose}>
              <span>&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Box>
              <Paper>
                <TableContainer style={{ maxHeight: 440, overflowX: 'auto' }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Číslo položky <ColumnFilter filterName="ItemId" initialValue={filterValues["ItemId"]} onChange={value => handleFilterChange("ItemId", value)} /></TableCell>
                        <TableCell>Výrobce <ColumnFilter filterName="ItsItemName3" initialValue={filterValues["ItsItemName3"]} onChange={value => handleFilterChange("ItsItemName3", value)} /></TableCell>
                        <TableCell>Sk. řád. ceny <ColumnFilter filterName="PurchLineDisc" initialValue={filterValues["PurchLineDisc"]} onChange={value => handleFilterChange("PurchLineDisc", value)} /></TableCell>
                        <TableCell>Název <ColumnFilter filterName="ItemName" initialValue={filterValues["ItemName"]} onChange={value => handleFilterChange("ItemName", value)} /></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredData.map((item, index) => (
                        <TableRow key={index} onClick={() => onSelectItem(item)} style={{ cursor: 'pointer' }}>
                          <TableCell>{item.ItemId}</TableCell>
                          <TableCell>{item.ItsItemName3}</TableCell>
                          <TableCell>{item.PurchLineDisc}</TableCell>
                          <TableCell>{item.ItemName}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Box>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={onClose}>Zavřít</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SearchProductModal;
